import React from "react";

const MountainRight = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 384 314" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="384" height="314" fill="none"/>
            <g id="mountainRight 1" clipPath="url(#clip0_0_1)">
                <g id="layer1">
                <path id="path11526" d="M383.2 313.765C383.2 313.765 369.842 315.803 360.715 283.548C351.589 251.294 340.912 195.092 340.912 195.092L334.25 166.93L310.137 143.27L298.878 157.342C298.878 157.342 285.232 136.817 285.476 131.523C285.719 126.228 280.069 76.0846 280.069 76.0846L230.951 38.4448L199.05 0L165.066 14.683L135.793 69.4421L127.188 112.297L84.0225 156.735L84.3714 177.974L71.6056 195.957C71.6056 195.957 71.7708 221.167 67.4345 228.926C63.0979 236.685 53.6497 240.229 46.727 246.543C39.8039 252.856 17.6508 273.059 13.3752 279.494C9.09965 285.93 3.05176e-05 313.791 3.05176e-05 313.791L383.2 313.765Z" fill="#2B4A48"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="383.2" height="313.791" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}

export default MountainRight;