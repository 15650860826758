import React from "react";

const MountainLeft = () => {
    return (
        <svg width="100%" height="100%" viewBox="0 0 469 389" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="469" height="389" fill="none"/>
            <g id="mountainLeft 1" g="url(#clip0_0_1)">
                <g id="layer1">
                    <path id="path11528" d="M468.202 388.04C468.202 388.04 451.88 390.53 440.729 351.12C429.579 311.711 416.534 243.042 416.534 243.042L408.393 208.633L378.932 179.726L365.175 196.92C365.175 196.92 348.502 171.842 348.8 165.373C349.097 158.904 342.194 97.6374 342.194 97.6374L301.449 0L248.461 15.9255L220.453 67.461L165.915 89.5214L155.401 141.883L102.66 196.178L103.086 222.128L87.489 244.1C87.489 244.1 87.6912 274.902 82.3927 284.382C77.0942 293.862 65.5501 298.192 57.0919 305.906C48.6331 313.62 21.566 338.304 16.3421 346.167C11.118 354.03 3.05176e-05 388.071 3.05176e-05 388.071L468.202 388.04Z" fill="#2B4A48"/>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_0_1">
                    <rect width="468.202" height="388.071" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default MountainLeft;